<template>
  <v-container fluid>
    <v-row dense>
      <section-title>Хабар юбориш</section-title>
      <v-spacer />
      <v-btn small dark class="blue darken-1" @click="openAddMessageDialog">
        <v-icon small class="mr-2">mdi-plus-box</v-icon>
        Qo'shish
      </v-btn>
    </v-row>

    <v-divider class="light-blue lighten-8 mt-2 mb-2 elevation-10" />

    <v-row dense>
      <v-col cols="12">
        <v-data-table
          fixed-header
          height="70vh"
          :headers="dataTable.headers"
          :items="dataTable.items"
          item-key="id"
          no-data-text="Ma'lumotlar mavjud emas !"
          disable-filter
          disable-sort
          :footer-props="{
            itemsPerPageOptions: [10, 15, 20, 30, 40, 50],
            itemsPerPageText: 'Jadvaldagi qatorlar soni: ',
          }"
          :server-items-length="dataTable.total"
          :options.sync="dataTable.options"
          dense
        >
          <template #item.text="{ item }">
            <span>{{ item.text.substr(0, 300) }}...</span>
          </template>

          <template #item.is_ready="{ item }">
            <v-btn
              icon
              v-show="!item.sent_messages_count"
              @click="toogleMessageStatus(item)"
            >
              <v-icon :color="item.is_ready ? 'success' : 'warning'">
                {{ item.is_ready ? "mdi-camera-timer" : "mdi-play" }}
              </v-icon>
            </v-btn>
            <v-btn v-show="item.sent_messages_count" icon disabled>
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </template>

          <template #item.actions="{ item }">
            <v-btn
              icon
              @click="openEditMessageDialog(item)"
              :disabled="!!item.sent_messages_count"
            >
              <v-icon>mdi-pen</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="sendTestMessage(item)"
              :disabled="!!item.sent_messages_count"
            >
              <v-icon color="warning">mdi-test-tube</v-icon>
            </v-btn>
          </template>
          <template #item.created_at="{ item }">
            <span>
              {{ item.created_at?.replace("T", " ")?.replace(".000000Z", "") }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <create-message-dialog
      ref="CreateMessageDialog"
      :after-save="fetchMessages"
    />
    <edit-message-dialog ref="EditMessageDialog" :after-save="fetchMessages" />
  </v-container>
</template>

<script>
import api from "../../axios.js";
import SectionTitle from "../../components/SectionTitle.vue";
import CreateMessageDialog from "./create-message-dialog.vue";
import EditMessageDialog from "./edit-message-dialog.vue";

export default {
  name: "Message",
  components: { SectionTitle, CreateMessageDialog, EditMessageDialog },
  data: () => ({
    dataTable: {
      items: [],
      total: 1,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        {
          text: "ID",
          value: "id",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "Text",
          value: "text",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "Yuborilgan xabarlar soni",
          value: "sent_messages_count",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "Xolati",
          value: "is_ready",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "Yaratilgan vaqti",
          value: "created_at",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "Amallar",
          value: "actions",
          divider: false,
          class: "blue lighten-1 white--text",
        },
      ],
    },
  }),
  watch: {
    "dataTable.options": "fetchMessages",
  },
  created() {
    this.fetchMessages();
  },
  methods: {
    async fetchMessages() {
      try {
        this.$overlay(true);

        const { data } = await api.get("/messages", {
          params: {
            page: this.dataTable.options.page,
            per_page: this.dataTable.options.itemsPerPage,
          },
        });

        this.dataTable.items = data.data;
        this.dataTable.total = data.meta.total;
      } catch (error) {
        this.$toast.error(error.response?.data?.message || error.message);
      } finally {
        this.$overlay(false);
      }
    },
    openAddMessageDialog() {
      this.$refs.CreateMessageDialog.openDialog();
    },
    openEditMessageDialog(message) {
      this.$refs.EditMessageDialog.openDialog(message);
    },
    async toogleMessageStatus(message) {
      if (confirm("Xabarni yuborishni xoxlaysizmi ?")) {
        try {
          this.$overlay(false);

          const { data } = await api.post("/messages/update-message", {
            message_id: message.id,
            is_ready: !message.is_ready,
          });

          await this.fetchMessages();
          this.$toast.success(
            data.message || "Xabarning xolati o'zgartirildi!"
          );
        } catch (error) {
          this.$toast.error(error.response?.data?.message || error.message);
        } finally {
          this.$overlay(false);
        }
      }
    },
    async sendTestMessage(message) {
      const chatId = prompt(
        "Xabarni test tariqasida yuborib ko'rish.\n\nXabar yuboriladigan chat id kiriting:"
      );
      if (!chatId) return;

      try {
        this.$overlay(true);

        const { data } = await api.post("/messages/send-test-message", {
          chat_id: chatId,
          message_id: message.id,
        });

        this.$toast.success(data.message || "Xabar yuborildi!");
      } catch (error) {
        this.$toast.error(error.response?.data?.message || error.message);
      } finally {
        this.$overlay(false);
      }
    },
  },
};
</script>
