<template>
  <v-dialog width="1000px" v-model="dialog" persistent>
    <v-card>
      <v-toolbar dark color="blue lighten-1" dense>
        <v-toolbar-title>Yangi xabar qo'shish</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="dialog = false" color="error">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col>
            <div class="pb-3">
              <v-img
                :src="message.imageBase64"
                style="height: 500px; width: 500px"
              />
            </div>
          </v-col>
          <v-col>
            <v-file-input
              accept="image/jpg, image/jpeg, image/png"
              label="Image"
              @change="onSelectedImage"
              outlined
              v-model="message.image"
            />

            <v-textarea
              outlined
              label="Text"
              height="400px"
              hide-details
              v-model="message.text"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn small color="warning" @click="dialog = false" class="w-40"
          >Bekor qilish</v-btn
        >
        <v-btn
          small
          @click="save"
          :disabled="!message.text"
          color="success"
          class="w-40"
          >Saqlash</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "../../axios.js";

export default {
  name: "CreateMessageDialog",
  props: {
    afterSave: {
      type: Function,
      default: async () => {},
    },
  },
  data: () => ({
    dialog: false,
    message: {
      text: "",
      imageBase64: null,
      image: null,
    },
  }),
  methods: {
    openDialog() {
      this.dialog = true;
    },
    onSelectedImage() {
      const reader = new FileReader();
      reader.onload = () => {
        this.message.imageBase64 = reader.result;
      };
      reader.readAsDataURL(this.message.image);
    },
    async save() {
      try {
        this.$overlay(true);

        const request = new FormData();
        request.append("text", this.message.text);

        if (this.message.image) {
          request.append("image", this.message.image);
        }

        const { data } = await api.post("/messages", request);

        this.$toast.success(data.message || "Xabar qo'shildi!");
        this.dialog = false;

        await this.afterSave();
      } catch (error) {
        this.$toast.error(error.response?.data?.message || error.message);
      } finally {
        this.$overlay(false);
      }
    },
  },
};
</script>
